export const Routes = {
	root: '/',
	login: '/login',
	signUp: '/sign-up',
	signUpVerification: '/sign-up/verification/:token',
	forgotPassword: '/forgot-password',
	forgotPasswordVerification: '/restore-password/verification/:token',
	home: '/home',
	reports: '/my-reports',
	subscriptions: '/subscriptions',
	exploration: '/exploration',
	resources: '/resources',
};

export const RoleBasedRoutes: Record<
	string,
	{
		path: string;
	}
> = {
	login: {
		path: Routes.login,
	},
	signUp: {
		path: Routes.signUp,
	},
	signUpVerification: {
		path: Routes.signUpVerification,
	},
	forgotPassword: {
		path: Routes.forgotPassword,
	},
	forgotPasswordVerification: {
		path: Routes.forgotPasswordVerification,
	},
	home: {
		path: Routes.home,
	},
	reports: {
		path: Routes.reports,
	},
	subscriptions: {
		path: Routes.subscriptions,
	},
	exploration: {
		path: Routes.exploration,
	},
	resources: {
		path: Routes.resources,
	},
};
