import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Routes } from 'routes/consts';

import AuthorisedLayout from 'components/layouts/authorised-layout';
import RootLayout from 'components/layouts/root-layout';
import UnauthorisedLayout from 'components/layouts/unauthorised-layout';

const Login = lazy(() => import('pages/auth/login'));
const SignUp = lazy(() => import('pages/auth/sign-up'));
const SignUpVerification = lazy(() => import('pages/auth/sign-up-verification'));
const ForgotPassword = lazy(() => import('pages/auth/forgot-password'));
const PasswordVerification = lazy(() => import('pages/auth/password-verification'));
const Reports = lazy(() => import('pages/reports'));
const Home = lazy(() => import('pages/home'));
const Exploration = lazy(() => import('pages/exploration'));
const Resources = lazy(() => import('pages/resources'));

export const RoutesConfig: RouteObject[] = [
	{
		path: Routes.root,
		element: (
			<AuthorisedLayout>
				<RootLayout />
			</AuthorisedLayout>
		),
		children: [
			{
				path: Routes.home,
				element: <Home />,
			},
			{
				path: Routes.reports,
				element: <Reports />,
			},
			{
				path: Routes.exploration,
				element: <Exploration />,
			},
			{
				path: Routes.resources,
				element: <Resources />,
			},
			// {
			// 	path: Routes.subscriptions,
			// 	element: <>Subscriptions</>,
			// },
		],
	},
	{
		path: Routes.root,
		element: (
			<UnauthorisedLayout>
				<Outlet />
			</UnauthorisedLayout>
		),
		children: [
			{
				path: Routes.login,
				element: <Login />,
			},
			{
				path: Routes.signUp,
				element: <SignUp />,
			},
			{
				path: Routes.signUpVerification,
				element: <SignUpVerification />,
			},
			{
				path: Routes.forgotPassword,
				element: <ForgotPassword />,
			},
			{
				path: Routes.forgotPasswordVerification,
				element: <PasswordVerification />,
			},
		],
	},
	{
		path: '*',
		element: <>Error</>,
	},
];
