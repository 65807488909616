import { Box } from '@mui/material';
import localspheredataImage from 'assets/images/localspheredata.jpg';
import { theme } from 'shared/theme';

type AuthContentLayoutPropsType = {
	children: React.ReactNode;
};

const AuthContentLayout = ({ children }: AuthContentLayoutPropsType) => {
	return (
		<Box
			sx={{
				backgroundSize: 'cover',
				height: '100vh',
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
			}}
		>
			<img
				src={localspheredataImage}
				alt="Logo"
				style={{ height: '50px', width: 'auto', alignSelf: 'flex-start', margin: theme.spacing(2) }}
			/>
			<Box
				sx={{
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					paddingLeft: 4,
					paddingRight: 4,
				}}
			>
				{children}
			</Box>
		</Box>
	);
};

export default AuthContentLayout;
