import { useEffect } from 'react';
import { Outlet } from 'react-router';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useUsersGetMe } from 'api/endpoints/combustionAI';
import { SIDEBAR } from 'constants/localstorage-consts';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useAuthStore } from 'store/auth';

import Header from './partials/header';
import Sidebar from './partials/sidebar';

const RootLayout = () => {
	const { setAuthData } = useAuthStore();
	const [open, setOpen] = useLocalStorage(SIDEBAR, true);

	const { data: meResponse, isPending } = useUsersGetMe();

	useEffect(() => {
		if (!isPending && meResponse) {
			setAuthData(meResponse);
		}
	}, [isPending, meResponse]);

	return (
		<Box sx={{ display: 'flex' }}>
			<Header open={open} toggleDrawer={setOpen} />
			<Sidebar open={open} toggleDrawer={setOpen} />
			<Box component="main" sx={{ flexGrow: 1, overflow: 'auto' }}>
				<DrawerHeader />
				<Box sx={{ p: 3 }}>
					<Outlet />
				</Box>
			</Box>
		</Box>
	);
};

export default RootLayout;

export const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	...theme.mixins.toolbar,
}));
