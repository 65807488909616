import { createTheme } from '@mui/material';

export const ColorPalette = {
	black: '#161616',
	darkGray: '#1e1e1e',
	primary: '#27aae2',
	red: '#a61d24',
	lightOrange: '#FFAF3E',
	green: '#009537',
	white: '#ffffff',
	gray: '#595959',
	semiLightGray: '#DFDFDF',
	lightGray: '#f4f4f4',
} as const;

const spacingFunction = (num: number) => `${4 * num}px`;

export const theme = createTheme({
	spacing: spacingFunction,
	palette: {
		primary: {
			main: ColorPalette.primary,
		},
		secondary: {
			main: ColorPalette.white,
		},
		error: {
			main: ColorPalette.red,
		},
		success: {
			main: ColorPalette.green,
		},
		warning: {
			main: ColorPalette.lightOrange,
		},
	},
	typography: {
		allVariants: {
			color: ColorPalette.black,
		},
		fontFamily: 'Comfortaa',
		h2: {
			fontSize: '60px',
			lineHeight: '46px',
			fontWeight: 700,
		},
		h3: {
			fontSize: '48px',
			lineHeight: '46px',
			fontWeight: 700,
		},
		h4: {
			fontSize: '34px',
			lineHeight: '38px',
			fontWeight: 700,
			letterSpacing: '0.25px',
		},
		h5: {
			fontSize: '26px',
			lineHeight: '27px',
			fontWeight: 700,
		},
		h6: {
			fontSize: '20px',
			lineHeight: '22px',
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		subtitle1: {
			fontSize: '18px',
			lineHeight: '20px',
			fontWeight: 700,
			letterSpacing: '0.15px',
		},
		body1: {
			fontSize: '14px',
			lineHeight: '19px',
			fontWeight: 400,
			letterSpacing: '0.5px',
		},
		body2: {
			fontSize: '16px',
			lineHeight: '18px',
			fontWeight: 500,
			letterSpacing: '0.25px',
		},
		button: {
			fontSize: '14px',
			fontWeight: 400,
			letterSpacing: '0.5px',
			textTransform: 'inherit',
		},
		caption: {
			fontSize: '12px',
			lineHeight: '16px',
			fontWeight: 400,
			letterSpacing: '0.4px',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor: ColorPalette.semiLightGray,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'&.Mui-disabled': {
						backgroundColor: ColorPalette.gray,
					},
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-root': {
						'fontSize': '12px',
						'& fieldset': {
							borderColor: ColorPalette.black,
						},
						'&:hover fieldset': {
							borderColor: ColorPalette.primary,
						},
					},
					'& .Mui-disabled': {
						overflow: 'hidden',
						backgroundColor: ColorPalette.gray,
					},
					'& .MuiInputBase-input': {
						padding: spacingFunction(3),
					},
					'& .MuiSelect-select': {
						padding: spacingFunction(3),
					},
					'& .MuiInputBase-multiline': {
						padding: 0,
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				root: {
					'& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
						padding: spacingFunction(2.3),
					},
				},
				option: {
					fontSize: '12px',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: ColorPalette.lightGray,
					padding: spacingFunction(10),
					borderRadius: 10,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: {
					backgroundColor: ColorPalette.lightGray,
					padding: `${spacingFunction(3)} ${spacingFunction(5)}`,
					borderRadius: 0,
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					padding: spacingFunction(1),
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					color: ColorPalette.black,
				},
			},
		},
	},
});
